<template>
  <div class="tradebox">
    <pcHeader />
    <div class="banner">
      <img src="../assets/tradebg.jpg" />
      <div class="banner-title">{{ $t("lang.trade.bannerTitle") }}</div>
    </div>
    <div class="trade">
      <div class="titles">{{ $t("lang.trade.titlesOne") }}</div>
      <div class="parpas">
        {{ $t("lang.trade.parpasOne") }}
      </div>
      <div class="maps">
        <img src="../assets/maps.png" />
      </div>
      <div class="titles">{{ $t("lang.trade.titlesTwo") }}</div>
      <!--      <div class="parpas">全方位流程化服务 · 精准化人群定位 · 高潜力爆款打造 · 带你掘金全球市场</div>-->
      <div class="parts">
        <div class="partsock">
          <div class="imgs"><img src="../assets/trade1.png" /></div>
          <div class="title">{{ $t("lang.trade.itemOne.title") }}</div>
          <span class="desc">{{ $t("lang.trade.itemOne.desc") }}</span>
        </div>
        <div class="partsock">
          <div class="imgs"><img src="../assets/trade2.png" /></div>
          <div class="title">{{ $t("lang.trade.itemTwo.title") }}</div>
          <span class="desc">{{ $t("lang.trade.itemTwo.desc") }}</span>
        </div>
        <div class="partsock">
          <div class="imgs"><img src="../assets/trade3.png" /></div>
          <div class="title">{{ $t("lang.trade.itemThree.title") }}</div>
          <span class="desc">{{ $t("lang.trade.itemThree.desc") }}</span>
        </div>
      </div>
    </div>
    <pcFooter />
  </div>
</template>
<script>
import pcHeader from "@/components/pcHeader";
import pcFooter from "@/components/pcFooter";
export default {
  components: {
    pcHeader,
    pcFooter,
  },
};
</script>
<style lang="scss">
.tradebox {
  .banner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 55px;
      color: #fff;
      font-weight: bold;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .trade {
    width: 1280px;
    margin: 0 auto;
    .titles {
      font-weight: bold;
      font-size: 26px;
      margin-top: 120px;
    }
    .parpas {
      font-size: 20px;
      font-weight: bold;
      color: #6d7276;
      margin-top: 40px;
    }
    .maps {
      width: 800px;
      margin: 0 auto;
      margin-top: 30px;
    }
  }
  .parts {
    display: flex;
    flex-direction: row;
    background: #f6f6f6;
    margin-top: 60px;
    margin-bottom: 100px;
    .partsock {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 20px;
      margin-top: 40px;
      width: calc(100% / 3);
      .imgs {
        margin: 40px 0 30px;
        width: 80px;
        img {
          width: 100%;
        }
      }
      .title {
        font-size: 24px;
        padding-bottom: 20px;
        text-align: center;
      }
      span {
        font-size: 16px;
        line-height: 32px;
        padding-bottom: 100px;
        width: 200px;
        color: #6d7276;
      }
    }
  }
}
</style>
